import React, { Component } from "react";
import "./Header.css";
import logo from './cullycode.png'

class Header extends Component {
  render() {
    const CURLY_BRACE = "{"
    return (
      <div class="header">
          <div className="header__container">
            <img className="header__logo" src={logo} alt="Cully Code Logo"/>
          </div>
      </div>
    );
  }
}

export default Header;
